<script setup lang="ts">
// import { useModal } from 'vue-final-modal'
// import NavigationSearchModal from './NavigationSearchModal.vue'

// const { currentDivision } = useDivisions()

const { isMacOS, isDesktop } = useDevice()

//TODO: DISABLED UNTIL WE HAVE SEARCH API
/*
const { open, close } = useModal({
  component: NavigationSearchModal,
  attrs: {
    id: 'modalSearch',
    clickToClose: true,
    escToClose: true,

    onConfirm() {
      close()
    },
    onOpened: () => {},
    onClosed: () => {},
  },
})

function handleKeyDown(event: any) {
  const macKeys = isMac && event.metaKey && event.code === 'KeyK'
  const winKeys = isWinOrLinux && event.ctrlKey && event.code === 'KeyK'
  if (macKeys || winKeys) {
    // open()
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown, false)
})

onUnmounted(() => {
  window.addEventListener('keydown', handleKeyDown, false)
})
*/
</script>

<template>
  <button
    ref="searchModal"
    class="translate ml-4 hidden h-[40px] self-center rounded-lg bg-gray-800 bg-opacity-60 px-1 text-white backdrop-blur-2xl backdrop-saturate-[180%] transition-all hover:bg-opacity-100 md:block"
  >
    <!-- @click="() =>  open()" -->
    <span class="flex flex-row items-center gap-2 px-4">
      <span class="text-sm">Search for leases</span>
      <span v-if="isDesktop" class="hidden space-x-1 text-xs xl:inline-block">
        <kbd>
          {{ isMacOS ? '⌘' : 'Ctrl' }}
        </kbd>
        <kbd>k</kbd>
      </span>
    </span>
  </button>
</template>
